<template>
	<div class="agreements">
		<van-nav-bar
		class="nav_bar_color"
		title="试驾协议设置"
		left-text="返回"
		left-arrow
		right-text="上传协议"
		@click-left="onClickLeft"
		@click-right="onClickright"
		fixed
		z-index="11" 
		:border="false"
		placeholder
		>
	</van-nav-bar>
		
		<div class="agreements_top" v-if="invite_info" @click="predivpdf(invite_info?.files)">
			<img src="@/assets/drivroute/icon_pdf.png" mode="">
			<div class="agreements_center">
				<span class="agreements_title">试乘试驾协议</span>
				<span class="agreements_name">{{invite_info?.username}}{{' '}}{{invite_info?.mobile}}</span>
			</div>
			<div class="agreements_left">
				<!-- <text>2020020202</text> -->
				<span>{{gettime(invite_info?.createtime)}}</span>
			</div>
		</div>
		
		<div class="dividing_line" v-if="invite_info">
		</div>
		
		<div v-if="invite_info" class="agreements_history_title">
			历史版本
		</div>
		
		<div v-if="invite_info" class="agreements_top isactive" v-for="v,index in invite_list"  @click="predivpdf(v.files)" :key="index">
			<img src="@/assets/drivroute/icon_pdf.png" mode="">
			<div class="agreements_center">
				<span class="agreements_title">试乘试驾协议</span>
				<span class="agreements_name">{{v.username}}{{' '}}{{v.mobile}}</span>
			</div>
			<div class="agreements_left">
				<!-- <text>2020020202</text> -->
				<span>{{gettime(v.createtime)}}</span>
			</div>
		</div>
		
		<div class="agreements_wushuju" v-if="!invite_info">
			<img src="@/assets/drivroute/icon_wushuju.png" mode="">
			<span>尚未上传协议</span>
			<div class="user_save" @click="onClickright">上传协议</div>
		</div>
		
		
		<!-- 底部 -->
		<!-- <div class="user_footer">

			<van-uploader :before-read="before" :after-read="uploadavatar">
				<div class="user_footer_add">
					<div class="user_save">上传协议</div>
				</div>
			</van-uploader>
		</div> -->

		<van-popup v-model="carModelPopup" class="agree"  position="bottom" >
			<div class="title">上传新的试乘试驾协议</div>
			<div class="concat">1. 请选择上传PDF格式的试乘试驾协议。</div>
			<div class="concat">2. 请确保协议PDF文件最后一页底部预留至少1/3页空白。</div>
			<div class="agree_border"><img src="@/assets/icon_pdf.png" alt=""><span>底部预留至少1/3页空白</span></div>
			<van-uploader accept=".pdf" :capture="null" :before-read="before" :after-read="uploadavatar">
				<div class="btn">选择一个PDF文件</div>
			</van-uploader>
		</van-popup>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				invite_info:{},
				invite_list:[],
				carModelPopup:false,
			};
		},
		mounted(){
			this.get_invite_list()
		},
		methods:{
			onClickright(){
				this.carModelPopup = true
			},
			before(file,detail){
				this.carModelPopup = false
				if(file.type == 'application/pdf'){
					return true
				}else{
					this.$toast.fail('请上传pdf文件')
				}
			},
			// 上传pdf文件
			async uploadavatar(e){
				let formData = new FormData();//通过formdata上传
				formData.append('file', e.file)
				const {data:res} = await this.$http.post('/api/alioss/upload',formData)
				if(res.code == 1){
						const {data:res2} = await this.$http.post('/api/scsj/upload_scsj_treaty',{file:res.data.res.url})
						if(res2.code == 1){
							this.$toast.success('上传成功')
							this.get_invite_list()
						}else{
							this.$toast.fail(res2.msg)
						}
					}
			},
			
			predivpdf(url){
				this.$router.push({path:`/view-pdf?contractUrl=${this.serverUrl(url)}`})
				console.log(this.serverUrl(url))
			},
			// 转换时间
			gettime(timestamp){
				const date = new Date(timestamp * 1000)
				const Y = date.getFullYear() + '年'
				const M = (date.getMonth() + 1 < 10 ? (date.getMonth() + 1) : date.getMonth() + 1) + '月'
				const D = (date.getDate() < 10 ? date.getDate() : date.getDate()) + '日'
				return Y + M + D
			},
			// 获取数据
			async get_invite_list(){
				const {data:res} = await this.$http.post('/api/scsj/get_scsj_treaty')
				if(res.code == 1){
					this.invite_list = res.data.res
					this.invite_info = this.invite_list?.splice(0,1)[0]
					console.log(this.invite_info)
				}
			},
		}
	}
</script>

<style lang="scss">
.agreements{
	padding-bottom: 180px;
}
.agreements_wushuju{
	padding: 500px 0 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	.user_save{
		margin-top: 20px;
		font-family: HYQiHeiY3-45;
		color: #fff;
		padding: 15px 40px;
		background-color: #FA0037;
	}
	img{
		width: 144px;
		height: 94px;
	}
	span{
		font-size: 32px;
		margin-top: 22px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #575757;
	}
}
.agreements_top{
	margin: 40px 32px;
	background: #373C46;
	border-radius: 16px;
	padding: 30px 16px;
	display: flex;
	// align-items: center;
	img{
		width: 84px;
		height: 84px;
	}
	.agreements_center{
		display: flex;
		flex-direction: column;
		margin-left: 15px;
		margin-top: 10px;
		width: 50%;
		.agreements_title{
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #FFFFFF;
			line-height: 36px;
		}
		.agreements_name{
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #B1B1B1;
			line-height: 36px;
		}
	}
	.agreements_left{
		flex: 1;
		margin-top: 5px;
		span{
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #9B9B9B;
			line-height: 40px;
		}
		
	}
}

.agreements_history_title{
	margin: 0 32px 10px;
	font-size: 32px;
	font-family: HYQiHeiY3-55;
	font-weight: normal;
	color: #9B9B9B;
	line-height: 32px;
}


.dividing_line{
	height: 1px;
	background: #333333;
	margin: 40px 32px;
}

.isactive{
	background: #222222 !important;
}

.user_footer{
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 182px;
	background: #000000;
	text-align: center;
	.user_footer_add{
		width: 670px;
		background: #FA0037;
		text-align: center;
		margin: 30px auto 0;
		line-height: 60px;
		font-size: 36px;
		font-family: HYQiHeiY3-65;
		font-weight: normal;
		color: #FFFFFF;
		.user_save{
			margin: 40px 32px 0;
			height: 100px;
			text-align: center;
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			background-color: #FA0037;
			color: #FFFFFF;
			line-height: 100px;
		}
		.user_footer_concat{
			padding-bottom: 20px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #F4F4F4;
			line-height: 32px;
			
		}
	}
}

.uni-file-picker__lists{
	display: none !important;
}

.agree{
	width: 750px;
	height: 880px;
	padding: 40px;
	box-sizing: border-box;
	background: #575757;
	border-radius: 46px 46px 0px 0px;
	.title{
		text-align: center;
		height: 40px;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 42px;
		margin-bottom: 40px;
	}
	.concat{
		// margin-top: 40px;
		font-size: 32px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 40px;
	}
	.agree_border{
		margin: 40px auto 0;
		width: 670px;
		height: 360px;
		background: #6F6F6F;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		img{
			width: 234px;
			height: 216px;
		}
		span{
			font-size: 28px;
			font-family: HYQiHeiY3-55;
			margin-top: 40px;
			font-weight: normal;
			color: #FFFFFF;
			line-height: 34px;
		}
	}
	.btn{
		width: 670px;
		height: 92px;
		text-align: center;
		margin: 60px auto 0;
		background: #FA0037;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 92px;
	}
}
</style>
